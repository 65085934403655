import { Button, Notification } from '@iheartradio/web.companion';
import { Link as RouterLink } from '@remix-run/react';
import { type ReactNode } from 'react';
import { route } from 'routes-gen';

import { useRegGateEvent } from '~app/analytics/use-reg-gate';
import { Routes } from '~app/utilities/constants';

export function AuthenticateCTANotification(props: {
  dismiss?: () => void;
  trigger: string;
  children?: ReactNode;
}) {
  const {
    dismiss,
    children = 'Log in to save your favorites and access Your Library',
    trigger = 'cta',
  } = props;
  const { onAnalyticsRegGateOpen, onAnalyticsRegGateExit } = useRegGateEvent();

  return (
    <Notification
      data-test="authenticate-cta-notification"
      footer={
        <>
          <Button
            as={RouterLink}
            color="gray"
            kind="tertiary"
            onClick={() => onAnalyticsRegGateOpen(trigger)}
            size={{ '@xsmall': 'small', '@medium': 'large' }}
            to={route(Routes.Login)}
          >
            Log in
          </Button>
          <Button
            as={RouterLink}
            color="gray"
            kind="tertiary"
            onClick={() => onAnalyticsRegGateOpen(trigger)}
            size={{ '@xsmall': 'small', '@medium': 'large' }}
            to={route(Routes.SignUp)}
          >
            Sign up
          </Button>
        </>
      }
      kind="info"
      onDismiss={() => {
        onAnalyticsRegGateExit(trigger);
        dismiss?.();
      }}
    >
      {children}
    </Notification>
  );
}
